* {
    box-sizing: border-box;
  }
  
  .form {
    width: 150vh;
    margin: 4rem auto ;
    display: flex;
    justify-content: space-between;
  }
  
  .form-field {
    flex: 2;
  }
  .output {
    margin-left: 3rem;
    flex: 1;
  }
  .form-field label,
  h2 {
    margin: 0;
    font-size: 18px;
    font-weight: 500;
    color: rgb(84, 84, 84);
  }
  
  input {
    height: 45px;
    width: 80%;
    padding: 7px;
    outline: none;
    border-radius: 5px;
    border: 1px solid rgb(220, 220, 220);
  }
  
  
  
  .devices {
    width: 100vh;
    display: flex;
    justify-content: space-between;
  }
  .first-division {
    display: flex;
    flex-direction: column;
    margin: 0 5px 0.5rem 0;
    width: 100%;
  }
  button {
    background: none;
    outline: none;
    cursor: pointer;
    font-weight: 500;
    border-radius: 2px;
    padding: 5px 10px;
  }
  .first-division button {
    align-self: flex-start;
    display: flex;
    align-items: center;
    margin-top: 0.5rem;
    color: #ffffff;
    font-weight: bold;
    border: 2px solid #005DAA;
    background-color: #005DAA;
  }
  .first-division button:hover {
    color: #005DAA;
    font-weight: bold;
    border: 2px solid #005DAA;
    background-color: #ffffff
  }
  
  .first-division button:focus {
    outline: none;
    border-color: #363636;
    box-shadow: 0 0 0 0.125em rgba(156, 157, 161, 0.25);
  }
  
  .second-division button {
    font-weight: bold;
    color: #ffffff;
    border: 1.75px solid red;
    background-color: red;
  }

  .second-division button:hover {
    background-color: #ffffff;
    color: red;
    border: 1.75px solid red;
  }
  
  .second-division button:focus {
    outline: none;
    border-color: #363636;
    box-shadow: 0 0 0 0.125em rgba(156, 157, 161, 0.25);
  }

  .submit button {
    font-weight: bold;
    color: #ffffff;
    border: 1.75px solid #4CAF50;
    background-color: #4CAF50;
  }

  .submit button:hover {
    border: 1.75px solid #4CAF50;
    background-color: white;
    color: #4CAF50
  }
  
  .submit button:focus {
    outline: none;
    border-color: #363636;
    box-shadow: 0 0 0 0.125em rgba(156, 157, 161, 0.25);
  }
