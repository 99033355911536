.h1 {
    color: white;
    font-size: 40px;
    display: "inline-block"

}

.h2 {
    color: white;
    font-size: 30px;
}

.h3 {
    color: white;
    font-size: 16px;
}

.tile{
    
    content: "";
    display: table;
    clear: both;
    

}