.App {
    font-family: sans-serif;
    text-align: center;
  }
  .modal-content {
    height: 500px;
  }
  .overlay-base {
    padding: 1rem;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
    transition-property: background-color, opacity;
    transition-duration: 800ms;
    transition-timing-function: ease-in-out;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .overlay-after {
    background-color: rgba(0, 0, 0, 0.8);
    opacity: 1;
  }
  
  .overlay-before {
    background-color: rgba(0, 0, 0, 0);
    opacity: 0;
  }
  
  .content-base {
    position: relative;
    /*
    top: auto;
    left: auto;
    right: auto;
    bottom: auto;
    */
    margin: 0 auto;
    border: 0;
    outline: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 0%;
    width: 0%;
    background-color: transparent;
    transition-property: background-color, width, height;
    transition-duration: 500ms;
    transition-timing-function: ease-in-out;
  }
  
  .content-after {
    width: 70%;
    height: 80%;
    background-color: rgba(250, 190, 190, 0.8);
  }
  
  .content-before {
    width: 0%;
    height: 0%;
    background-color: transparent;
  }
  
 