label {
  display: block;
  margin: 10px 0;
}

input {
  margin-left: 10px;
}

axis-label {
  color: #fff;
}
