.progress {
  background-color: none;
  border-radius: 3px;
  border: 3px solid #1D1D1D;
  box-shadow: none;
  margin-top:30px
}

.progress.vertical {
  position: relative;
  width: 100px;
  height: 170px;
  display: inline-block;
  margin-right: 10px;
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: none;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress.vertical > .progress-bar {
  width: 100% !important;
  position: absolute;
  bottom: 0;
}

.progress-bar {
  background-color: #2196f3;
  box-shadow: none;
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 10px;
  -webkit-text-stroke: 0.4px black;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #1ed760;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  transition: width 2s, height 2s, background-color 2s, transform 2s;
}
